import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash"
export default class FlashController extends Controller<HTMLElement> {
  static targets: string[] = ["flashMessage"];

  declare readonly hasFlashMessageTarget: boolean;
  declare readonly flashMessageTarget: HTMLInputElement;
  declare readonly flashMessageTargets: HTMLInputElement[];

  flashMessageTargetDisconnected(_target: Element) {
    const hidden = this.flashMessageTargets.length === 0;

    this.element.hidden = hidden;
  }
}
