import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dismiss"
export default class DismissController extends Controller<HTMLElement> {
  static values = {
    interval: Number,
  };

  declare readonly hasIntervalValue: boolean;
  declare readonly intervalValue: number;

  connect() {
    if (this.hasIntervalValue) {
      setTimeout(() => this.dismiss(), this.intervalValue);
    }
  }

  dismiss() {
    this.element.remove();
  }
}
